<template>
  <b-card>
    <div
      v-if="loader"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <validation-observer
      v-else
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col
          md="8"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Request Code"
          >
            <b-form-group
              label="Request Code "
              label-for="Request Code"
            >
              <b-input
                id="RequestCode"
                v-model="requestCode"
                :state="getValidationState(validationContext)"
                trim
                readonly
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Engineer"
          >

            <b-form-group
              label="Engineer "
              label-for="Engineer"
            >
              <v-select
                id="engineer"
                v-model="inspectionRequestForm.inspector"
                :state="getValidationState(validationContext)"
                :options="optionUser"
                trim
                placeholder="Select Engineer"
                :clearable="false"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Date"
          >

            <b-form-group
              label="Date "
              label-for="Date"
            >
              <b-form-datepicker
                id="date"
                v-model="inspectionRequestForm.date"
                class="mb-2"
                type="date"
                placeholder="Inspection Date"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addInspectionRequest()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addInspectionRequest()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    'v-select': VSelect,
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
      optionUser: [],
      requestCode: '',
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const inspectionRequestForm = ref({
      inspector: {
        value: null,
        label: null,
      },
    })
    return {
      getValidationState,
      inspectionRequestForm,
    }
  },
  mounted() {
    this.showInspection()
    this.getEngineer()
  },
  methods: {
    getEngineer() {
      axios.get('https://gulftic-system.fci.group/api/engineers/list').then(res => {
        this.optionUser = decryptData(res.data.payload).data.engineers.map(item => ({
          value: item.id,
          label: item.name,
        }))
      })
    },
    async showInspection() {
      this.loader = true
      if (this.$route.params.id) {
        await axios.get(`request-inspection/${this.$route.params.id}`).then(res => {
          const getInspectionData = decryptData(res.data.payload).data.inspection
          this.inspectionRequestForm.date = getInspectionData.date
          this.requestCode = getInspectionData.request.request_code
          if (getInspectionData.inspector) {
            this.inspectionRequestForm.inspector = {
              value: getInspectionData.inspector.id || '',
              label: getInspectionData.inspector.name || '',
            }
          }
        })
      } else {
        this.loader = false
        return false
      }
      this.loader = false
      return true
    },

    addInspectionRequest() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        Object.keys(this.inspectionRequestForm).forEach(key => {
          if (key === 'inspector') {
            formData.append(key, this.inspectionRequestForm[key]?.value || null)
          } else if (Array.isArray(this.inspectionRequestForm[key])) {
            this.inspectionRequestForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, this.inspectionRequestForm[key])
          }
        })

        axios.post(`request-inspection/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'request-inspection-pending' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
